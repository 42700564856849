import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Img from "gatsby-image";
import { BackgroundSection, Section } from "../components/section";
import { Container, Row, Col } from "reactstrap";
import Layout from '../components/layout';
export const query = graphql`
   {
    tiendaImg:     file(relativePath: { eq: "tienda.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1024) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
    }
  }
`;
export const _frontmatter = {
  "title": "Tenda",
  "order": 2
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  query,
  _frontmatter
};

const MDXLayout = ({
  children,
  ...props
}) => <Layout {...props}>
  <Section>
    <Container>
      <Row>
        <Col md={6}>
          {children}
        </Col>
        <Col md={6}>
          <Img fluid={props.data.tiendaImg.childImageSharp.fluid} className="shadow" />
        </Col>
      </Row>
    </Container>
  </Section>
</Layout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Tenda`}</h1>
    <p>{`Estem situats al Carrer Torregassa 29 de l'Ametlla del Vallès.`}</p>
    <p>{`Al darrera de la tenda tenim l'obrador, on elavorem tots els nostres productes de manera artesana en el forn de llenya.`}</p>
    <p>{`.`}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      